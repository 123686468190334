import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const DOMAIN_ENG = 'lamacat';
const DOMAIN = '라마켓'

export default new Vuex.Store({
    state: {
        domain: DOMAIN,
        user: null,
        uid: null,
        providerData: null,
        userRole: null,
        claims: null,
        serverUrl: `http://127.0.0.1:5001/${DOMAIN_ENG}/asia-northeast3`,
    },
    plugins: [
        createPersistedState({
            key: 'vuex',
            paths: ['uid', 'providerData', 'userRole']
        })
    ],
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setUid(state, uid) {
            state.uid = uid
        },
        setProviderData(state, providerData) {
            state.providerData = providerData
        },
        setClaims(state, claims) {
            state.claims = claims
        },
        setUserRole(state, userRole) { // 추가된 코드
            state.userRole = userRole
        }
    },
    actions: {
        async fetchUser({commit}, user) {
            if (!user) return false
            commit('setUser', user)
            commit('setUid', user.uid)
            commit('setProviderData', user.providerData[0])
            const tokenResult = await user.getIdTokenResult(true)
            const claims = tokenResult.claims
            commit('setClaims', claims)
            const userRole = claims['userRole']
            commit('setUserRole', userRole)
        },
        async clearUserData({commit}) {
            commit('setProviderData', null)
            commit('setUserRole', null);
            commit('setUser', null);
            commit('setUid', null);
            commit('setClaims', null);

        },
    }
})
