import Vue from 'vue';
import VueRouter from 'vue-router';
import {onAuthStateChanged, unsubscribeAuthStateChanged} from '@/firebase/firebaseAuth';
import store from '@/store';
import 'firebase/auth';

import routes from './routes';

Vue.use(VueRouter);


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const makeTitle = (title) => title ? `${title} | ${store.state.domain}` : `${store.state.domain}`;

router.afterEach((to) => {
    unsubscribeAuthStateChanged();
    Vue.nextTick(() => {
        document.title = makeTitle(to.meta.title);
    });
});

router.beforeEach(async (to, from, next) => {
    const storedUserRole = localStorage.getItem('userRole');
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        store.commit('setUser', JSON.parse(storedUser));
    }

    if (storedUserRole) {
        handleNavigation(to, next, storedUserRole);
    } else {
        await onAuthStateChanged();
        handleNavigation(to, next, store.state.userRole);
    }
});

function handleNavigation(to, next, userRole) {
    const allowedRoles = to.meta.allowedRoles;

    if (store.state.user) {
        console.log('로그인 정보', store.state.user);
        // 로그인 상태일 때 추가적인 작업을 할 수 있습니다.
    } else {
        console.log('없다.');
    }

    if (to.meta.authRequired) {
        if (!userRole) {
            if (to.path.includes('/master')) {
                next({name: 'MasterLogin'});
            } else {
                next({name: "Login"});
            }
        } else if (allowedRoles && !allowedRoles.includes(userRole)) {
            next({name: "AccessDenied"});
        } else {
            next();
        }
    } else {
        next();
    }
}


export default router;