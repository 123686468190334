import { USER_ROLES } from './userRoles'; // Updated import

const routes = [
    {
        // =============================================================================
        // COMMON LAYOUT ROUTES
        // =============================================================================
        path: '',
        component: () => import('@/layouts/common/CommonView.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('../views/Home.vue'),
                meta: {title: '홈'}
            },
            {
                path: '/category/:type',
                name: 'Category',
                component: () => import('../views/CategoryDetail.vue'),
                meta: {title: '카테고리 상세'}
            },
            {
                path: '/category/:type/item/:uid',
                name: 'ItemDetail',
                component: () => import('../views/ItemDetail.vue'),
                meta: {title: '아이템 상세'}
            },
            {
                path: '/category/:type/item/:uid/purchase',
                name: 'ItemPurchase',
                component: () => import('../views/ItemPurchase.vue'),
                meta: {title: '아이템 구매'}
            },
            {
                path: '/category/:type/item/:uid/purchase/success',
                name: 'ItemPurchaseSuccess',
                component: () => import('../views/ItemPurchaseSuccess.vue'),
                meta: {title: '아이템 구매완료', authRequired: true, allowedRoles: [USER_ROLES.USER]}
                // meta: {title: '아이템 구매완료'}
            },
            {
                path: '/user/myPage',
                name: 'MyPage',
                component: () => import('../views/UserMyPage.vue'),
                meta: {title: '마이페이지', authRequired: true, allowedRoles: [USER_ROLES.USER]},
            },
            {
                path: '/user/myPage/:uid/:itid',
                name: 'MyPaymentDetail',
                component: () => import('../views/master/PaymentDetail.vue'),
                meta: {title: '내 구매 내역 상세'}
            },
            {
                path: '/user/myPage/addItem',
                name: 'AddItem',
                component: () => import('../views/ItemForm.vue'),
                meta: {title: '게시물 등록', authRequired: true, allowedRoles: [USER_ROLES.USER]},
            },
            {
                path: '/user/myPage/myItem/detail/:uid',
                name: 'MyItemDetail',
                component: () => import('../views/ItemDetail.vue'),
                meta: {title: '내 게시물 상세'}
            },
            {
                path: '/user/myPage/myItem/editItem/:itid',
                name: 'EditItem',
                component: () => import('../views/ItemForm.vue'),
                meta: {title: '게시물 수정', authRequired: true, allowedRoles: [USER_ROLES.USER]},
            },
            {
                path: '/search/:text',
                name: 'ItemSearch',
                component: () => import('../views/ItemSearch.vue'),
                meta: {title: '아이템 검색'}
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('../views/Login.vue'),
                meta: {title: '로그인'}
            },
            {
                path: '/signUp',
                name: 'SignUp',
                component: () => import('../views/SignUp.vue'),
                meta: {title: '회원가입'}
            },
        ],
    },
    {
        // =============================================================================
        // MASTER LAYOUT ROUTES
        // =============================================================================
        path: '',
        component: () => import('@/layouts/master/MasterView.vue'),
        children: [
            {
                path: '/master',
                name: 'master',
                redirect: '/master/main'
            },
            {
                path: '/master/main',
                name: 'MasterMain',
                component: () => import('../views/master/MasterMain.vue'),
                meta: {title: '관리자 메인', authRequired: true, allowedRoles: [USER_ROLES.MASTER]}
            },
            {
                path: '/master/post',
                name: 'PostManage',
                component: () => import('../views/master/PostManage.vue'),
                meta: {title: '게시물 관리', authRequired: true, allowedRoles: [USER_ROLES.MASTER]}
            },
            {
                path: '/master/addPost',
                name: 'AddPost',
                component: () => import('../views/ItemForm.vue'),
                meta: {title: '게시물 등록', authRequired: true, allowedRoles: [USER_ROLES.MASTER]},
            },
            {
                path: '/master/post/:uid',
                name: 'PostDetail',
                component: () => import('../views/ItemDetail.vue'),
                meta: {title: '아이템 상세', authRequired: true, allowedRoles: [USER_ROLES.MASTER]},
            },
            {
                path: '/master/post/editPost/:itid',
                name: 'EditPost',
                component: () => import('../views/ItemForm.vue'),
                meta: {title: '게시물 수정', authRequired: true, allowedRoles: [USER_ROLES.MASTER]},
            },
            {
                path: '/master/payment',
                name: 'PaymentManage',
                component: () => import('../views/master/PaymentManage.vue'),
                meta: {title: '결제 관리', authRequired: true, allowedRoles: [USER_ROLES.MASTER]}
            },
            {
                path: '/master/payment/:uid/:itid',
                name: 'PaymentDetail',
                component: () => import('../views/master/PaymentDetail.vue'),
                meta: {title: '아이템 결제 상세'}
            },
            {
                path: '/master/myInfo',
                name: 'MasterMyInfo',
                component: () => import('../views/master/MasterMyInfo'),
                meta: {title: '관리자 정보', authRequired: true, allowedRoles: [USER_ROLES.MASTER]}
            },
        ],
    },
    {
        path: '/master/login',
        name: 'MasterLogin',
        component: () => import('../views/master/MasterLogin'),
        meta: {title: '관리자 로그인'}
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('../views/Error404'),
        meta: {title: '404'}
    },
    {
        path: '/access-denied',
        name: 'AccessDenied',
        component: () => import('../views/AccessDenied.vue'),
        meta: {title: '접근 거부'},
    },
    {
        path: '*',
        redirect: '/error-404'
    }
];

export default routes;