import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage';


// 실 서버
firebase.initializeApp({
    apiKey: "AIzaSyCrC8oh2bieJqUKa8Zb-SSquFhhmIe2TmM",
    authDomain: "lamacat.firebaseapp.com",
    projectId: "lamacat",
    storageBucket: "lamacat.appspot.com",
    messagingSenderId: "741062852932",
    appId: "1:741062852932:web:fa830a170806f530ee1711",
    measurementId: "G-TV93L96PX3"
});

firebase.auth().languageCode = 'ko'

const auth = firebase.auth()
const firestore = firebase.firestore()
const Storages = firebase.storage();
const storage = firebase.storage;
const functions = firebase.app().functions('asia-northeast3')
const Timestamp = firebase.firestore.Timestamp
const FieldValue = firebase.firestore.FieldValue

export {auth, Storages, storage, firestore, functions, Timestamp, FieldValue}






