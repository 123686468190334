import { auth } from '@/firebase/firebaseConfig';
import router from "@/router";
import store from "@/store";

let unsubscribe = null;

auth.onAuthStateChanged(async (user) => {
    if (user) {
        // 로그인한 경우
        await store.dispatch("fetchUser", user);
    } else {
        // 로그아웃한 경우
        await store.dispatch('clearUserData');
    }
});

export const signOut = async () => {
    try {
        await auth.signOut();
        localStorage.removeItem('userRole')
        if (router.currentRoute.path !== '/') {
            await router.push('/');
        } else await router.go(0);
        // console.log('로그아웃 성공');
    } catch (error) {
        console.error('Error signing out:', error);
    }
};

export const onAuthStateChanged = () => {
    return new Promise((resolve) => {
        unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                store.dispatch("fetchUser", user).then(resolve);
            } else {
                store.dispatch('clearUserData');
                resolve();
            }
        });
    });
};

export const unsubscribeAuthStateChanged = () => {
    if (unsubscribe) {
        unsubscribe();
    }
};