import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@/firebase/firebaseConfig'
import '@/assets/css/main.css'
import 'sweetalert2/dist/sweetalert2.min.css';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2';

const options = {
    confirmButtonColor: '#C2363B', cancelButtonColor: '#EB973E',
};
Vue.use(VueSweetalert2, options);

Vue.config.productionTip = false

import Notify from 'mdbvue/lib/components/Notify'

Vue.use(Notify)

// apexcharts
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
    router, store, render: h => h(App)
}).$mount('#app')
